<template>
    <div class="portfolio-table" style="position: relative;">
        <TableGraphSwitcher @change-viewmode="changeView" :isPortfolio="2" />
        <a-row>
        <a-col :sm="24" :xs="24" v-if="!isGraph">
            <portfolio-control-table />
        </a-col>
        <a-col :xs="24" v-else>
            <portfolio-control-graph />
        </a-col>
        </a-row>
    </div>
</template>

<script setup>
import { ref } from "vue";
import TableGraphSwitcher from "@/components/wiseral/TableGraphSwitcher.vue";
import PortfolioControlTable from "@/components/wiseral/control/PortfolioControlTable.vue";
import PortfolioControlGraph from "@/components/wiseral/control/PortfolioControlGraph.vue";

const isGraph = ref(false);

const changeView = (e) => {
isGraph.value = e;
};
</script>