<template>
  <!-- CARTERA -->
  <div style="background: #f2f2f2;">
    <div class="mx-3 optimize-control">
      <a-row gutter="16">
        <template v-if="!authStore.restrictions.includes(Restrictions['OptimizePortfolio'])">
          <a-col class="collapse-label-btn" @click="toggleOptimize">
            <div class="control-info">
              <Info :page-key="'optimize_portfolio'" />
            </div>
            <label>{{ t('generic.titles.optimize_portfolio') }}</label>
            <wiser-icon
              style="transform: scale(0.8);"
              icon="chevron down"
              v-if="optimizationCollapsed === 0"
            />
            <wiser-icon
              style="transform: scale(0.8);"
              icon="chevron up"
              v-else
            />
          </a-col>
        </template>
        <template v-if="!authStore.restrictions.includes(Restrictions['FixWeight'])">
          <a-col class="collapse-label-btn" @click="toggleFixWeight">
            <div class="control-info">
              <Info :page-key="'fix_weights'" />
            </div>
            <label>{{ t('generic.titles.fix_weights') }}</label>
            <wiser-icon
              style="transform: scale(0.8);"
              icon="chevron down"
              v-if="fixWeightCollapsed"
            />
            <wiser-icon
              style="transform: scale(0.8);"
              icon="chevron up"
              v-else
            />
          </a-col>
        </template>
      </a-row>
      <div
        class="collapssible-menu"
        :style="{ 
          'flex-direction': optimizationCollapsed < optimizationStatus.EXPANDED ? 'row' : 'column',
          'width': optimizationCollapsed > optimizationStatus.COLLAPSED ? '100%' : '300px',
        }"
        v-if="optimizationCollapsed > 0"
      >
        <div
          class="flex align-items-center justify-content-between"
          :style="{ width: optimizationCollapsed < optimizationStatus.EXPANDED ? '480px' : '100%' }"
        >
          <limits-component
            :is-collapsed="optimizationCollapsed"
            :show-save="true"
            @changeCollapsed="changeCollapsed($event)"
            @selectLimit="selectLimit($event)"
          ></limits-component>
        </div>
        <a-row
          type="flex"
          :style="{
            'align-items': 'center',
            'justify-content': optimizationCollapsed < optimizationStatus.EXPANDED ? 'flex-end' : 'center',
            width: optimizationCollapsed === optimizationStatus.EXPANDED && '100%',
            width: '100%',
            'font-size': '14px'
          }"
          v-if="optimizationCollapsed >= optimizationStatus.INTERMEDIATE"
        >
          <div class="py-2 px-3 d-block">
            <a-checkbox v-model:checked="new_candidates">
              {{ $t("funds_printer.new_candidates") }}
            </a-checkbox>
          </div>
          <div>
            <span class="py-3 d-block control-head">{{
              $t("funds_printer.risk_level")
            }}</span>
          </div>
          <div :md="3" :xs="16" class="px-3 py-4">
            <a-select
              mode="select"
              v-model:value="riskLevel"
              style="width: 100px; height: 36px"
              :placeholder="$t('generic.options.select_one')"
              class="native-select"
              @focus="focus"
              ref="select"
            >
              <a-select-option
                v-for="(i, key) in authStore.config.risk_levels"
                :key="key"
                :value="key"
                :title="$t(`generic.risk_options.${key}`, key)"
              >
                {{ $t(`generic.risk_options.${key}`, key) }}
              </a-select-option>
            </a-select>
          </div>
          <div
            ><span class="py-3 d-block"
              >{{ $t("funds_printer.max_vol") }}
            </span></div
          >
          <div class="py-2 px-3 d-block">
            <percent-input
              v-model="manualRiskLevel"
              placeholder="0"
              :min="0"
              :max="50"
              :style="{ height: '36px' }"
            />
          </div>
          <div class="optimize-button-placement">
            <sdButton
              size="small"
              type="secondary"
              class="cta float-right"
              @click="handleOptimize"
              :loading="portfolioStore.loadingOptimize"
              :disabled="!manualRiskLevel && !riskLevel"
            >
              {{
                $t("funds_printer.optimize_portfolio")
              }}
            </sdButton>
          </div>
        </a-row>
      </div>
      <div class="collapssible-menu justify-content-end" v-if="!fixWeightCollapsed">
        <a-col class="py-2 px-3 d-flex align-items-center gap-1">
          <percent-input
            v-model="fixWeightSum"
            placeholder="0"
            :min="0"
            :disabled="true"
            :style="{ height: '36px' }"
          />
          <p style="font-size: 14px; margin-bottom: 0px;">{{ $t("funds_printer.weight_sum", "Sum") }}</p>
        </a-col>
        <a-col class="optimize-button-placement">
          <sdButton
            size="small"
            type="secondary"
            class="cta float-right"
            :class="{'bg-light text-secondary': fixWeightSum !== 1 }"
            @click="handleFixWeights"
            :disabled="fixWeightSum !== 1"
            :loading="portfolioStore.loadingFixWeights"
          >
            {{
              $t("generic.buttons.fix_weights")
            }}
          </sdButton>
        </a-col>
      </div>
    </div>
 
  <div class="table-btn-group">
    <a-space class="w-100 justify-content-between">
      <a-space>
        <a-tooltip :title="t('funds_printer.select_all_switch_tooltip')">
          <a-switch class="mb-1" style="margin-right: 12px;" v-model:checked="selectAll" />
        </a-tooltip>
        <template v-if="enableActions">
          <a-tooltip :title="t('funds_printer.delete_selection_tooltip')">
            <label
              v-if="showDelete && !authStore.restrictions.includes(Restrictions['RemoveSelection'])"
              class="label-button"
              @click="clearSelectedBasket()"
            >
              {{
                t("funds_printer.delete_selection")
              }}
            </label>
          </a-tooltip>
        </template>
        <template v-else>
          <a-popconfirm
            title="¿Estás seguro de eliminar todos?"
            :ok-text="$t('search_funds.select_family_columns.yes')"
            placement="left"
            :cancel-text="$t('search_funds.select_family_columns.no')"
            @confirm="clearBasket"
          >
          <a-tooltip :title="t('funds_printer.delete_all_tooltip')">
              <label
                v-if="showDelete && !authStore.restrictions.includes(Restrictions['RemoveSelection'])"
                class="label-button"
              >{{
                  t("funds_printer.delete_all")
                }}
              </label>
            </a-tooltip>
          </a-popconfirm>
        </template>
        <a-tooltip :title="t('funds_printer.add_to_basket_tooltip')">
          <label
            class="label-button"
            @click="addToBasket()"
            v-if="!authStore.restrictions.includes(Restrictions['AddToBasket'])"
          >
            {{ $t("funds_printer.add_to_basket") }}
          </label>
        </a-tooltip>
        <a-popover
          v-model:visible="visibleReport"
          title=""
          trigger="click"
          placement="bottom"
          v-if="!authStore.restrictions.includes(Restrictions['PdfGeneration'])"
          >
          <template #content>
            <a-row>
              <a-span
                class="report-type-button"
                :xs="12"
                @click="printPdf()"
              >
                <wiser-icon icon="pdf" /> {{ t('funds_printer.pdf_report') }}
              </a-span>
              <a-span
                class="report-type-button"
                :xs="12"
                @click="printAsset()"
              >
                <wiser-icon icon="file" /> {{ t('funds_printer.asset_report') }}
              </a-span>
            </a-row>
          </template>
          <a-tooltip :title="t('funds_printer.reports_tooltip')">
            <label
              class="label-button"
              @click="visibleReport = !visibleReport"
            >
              {{ t("funds_printer.reports") }}
              <a-spin style="padding-left: 2px; padding-bottom: 3px;" :indicator="indicatorSmall" v-if="printing"></a-spin>
            </label>
          </a-tooltip>
        </a-popover>
      </a-space>
      <a-space class="align-items-center">
        <a-tooltip :title="t('generic.titles.select_columns_tooltip')">
          <label
            class="label-button"
            @click="showColumnsModal('basket')"
            v-if="!authStore.restrictions.includes(Restrictions['CustomizeColumns'])"
          >
          {{ t("generic.titles.select_columns") }}
          </label>
        </a-tooltip>
        <div class="paginationSelectorRow pb-1">
          <label>
            {{ portfolioStore?.getPositionsTable?.length ?? 0 }} {{ t('generic.titles.table_length') }}
          </label>
          <select v-model="portfolioStore.selectedPageSize" class="native_page_select">
            <option
              v-for="pageSize in paginationPageSizeOptions"
              v-bind:key="pageSize"
              :value="pageSize"
            >
              {{ pageSize }}
            </option>
          </select>
        </div>    
      </a-space>  
    </a-space>
  </div>
  <!-- FIN CARTERA -->
  <a-row style="width: 250px; margin-left: auto; margin-bottom: 4px;" v-if="optimizationCollapsed > optimizationStatus.COLLAPSED">
    <a-span style="width: 50%;">
      <label
        class="weight-set-btn"
        :class="{ 'disabled': disableReleaseOrDiscard }"
        @click="releaseOrDiscard('release')"
      >
        {{ $t("funds_printer.release") }}
      </label>
    </a-span>
    <a-span style="width: 50%;">
      <label
        class="weight-set-btn"
        :class="{ 'disabled': disableReleaseOrDiscard }"
        @click="releaseOrDiscard('discard')"
      >
        {{ $t("funds_printer.discard") }}
      </label>
    </a-span>
  </a-row>
  <div class="mobile-table-length">
      <label>
        {{ portfolioStore?.getPositionsTable?.length ?? 0 }} {{ t('generic.titles.table_length') }}
      </label>
  </div>

  <div class="portfolio-mobile-data">
    <full-table
      ref="tableRef"
      :columnDefs="columnDefs"
      @selection-changed="selectionChanged"
      @column-moved="onColumnMoved"
      @column-removed="onColumnRemoved"
      @cell-edited="calculateFixWeightSum"
      :rowData="portfolioStore.getPositionsTable"
      :selectAll="selectAll"
    >
    </full-table>
    <div class="loading-wrapper" v-if="portfolioStore.reloading">
        <a-spin :indicator="indicatorLarge" />
    </div>
  </div>

  <a-modal
    v-model:visible="visibleColumnModal"
    :title="$t('generic.titles.select_columns')"
    :type="'primary'"
    :closable="false"
  >
  <template #footer>
      <a-button key="ok" @click="hideColumnsModal">Ok</a-button>
    </template>
    <a-select
      mode="multiple"
      v-model:value="portfolioStore.activeColumns"
      :placeholder="$t('generic.titles.select_columns')"
      class="native-select"
      :size="'small'"
      :filterOption="customFilter"
    >
    <a-select-option
        v-for="col in portfolioStore.allPortfolioCols"
        :key="$t(`positions_fields.${col.key}`, col.key)"
        :value="col.key"
        :title="$t(`positions_fields.${col.key}`, col.key)"
      >
        {{ $t(`positions_fields.${col.key}`, col.key) }}
      </a-select-option>
    </a-select>
    <div v-if="availableSelect" class="text-right">
      {{ t("generic.titles.available_text") }}
    </div>
    <div v-else class="text-right">
      {{ t("generic.titles.no_available_text") }}
    </div>
  </a-modal>
  <pdf-printer ref="pdfprinter" @has-downloaded="hasDownloaded">
    <template v-slot:body-pdf>
      <sdPageHeader :title="$t('portfolio_overview.title')"></sdPageHeader>
      <template v-if="portfolioStore.selectedPortfolio">
        <portfolio-resume></portfolio-resume>
        <table-printer
          :datasets="getDatasets"
          :key-title="$t('portfolio_overview.columns.portfolio')"
          :display-headers="true"
        ></table-printer>
        <PortfolioGraph></PortfolioGraph>
      </template>
      <full-table
        :columnDefs="columnDefs"
        :rowData="portfolioStore.getPositionsTable"
        :pagination="false"
      >
      </full-table>
    </template>
  </pdf-printer>
  <div id="asset-graph-pdf" class="print-graph-container">
    <basket-graph :graph="printGraph" :hide-buttons="true"></basket-graph>
  </div>
</div>
</template>

<script setup>
import { useBasketStore } from "@/stores/BasketStore";
import WiserIcon from "@/components/icons/wiser-icons";
import { ref, computed, watch, onMounted } from "vue";
import pdfPrinter from "@/components/pdfPrinter";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import FullTable from "@/components/wiseral/FullTable";
import PortfolioResume from "@/components/wiseral/portfolio/PortfolioResume.vue";
import tablePrinter from "@/components/wiseral/tablePrinter.vue";
import PortfolioGraph from "@/components/wiseral/PortfolioGraph.vue";
import BasketGraph from "@/components/wiseral/BasketGraph.vue";
import Cookies from "js-cookie";
import Restrictions from "../../../config/restriction/restrict_functions";
import { message } from "ant-design-vue";
import LimitsComponent from "@/components/wiseral/filters/limits-component";
import PercentInput from "@/components/wiseral/PercentInput";
import { LoadingOutlined } from '@ant-design/icons-vue';
import { h } from 'vue';
import Info from "@/components/infoModal/index.vue";

const { t, n, d, te } = useI18n();
const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();
const portfolioLimitsStore = usePortfolioLimitStore();
const basketStore = useBasketStore();
const visibleColumnModal = ref(false);
const tableType = ref("");
const riskLevel = ref(null);
const max_weight = ref(null);
const only_my_bank = ref(false);
const new_candidates = ref(false);
const manualRiskLevel = ref(null);
const enableActions = ref(false);
const tableRef = ref(null);
const pdfprinter = ref(null);
const printing = ref(false);
const availableSelect = ref(false);
const selectAll = ref(false);
const optimizationCollapsed = ref(0);
const fixWeightCollapsed = ref(true);
const printGraph = ref([]);
const fixWeightSum = ref(0);
const disableReleaseOrDiscard = ref(true);
const visibleReport = ref();

const optimizationStatus = {
COLLAPSED: 0,
INTERMEDIATE: 1,
EXPANDED: 2
};

let paginationPageSizeOptions = [20, 50, 100, 500];

const indicatorSmall = h(LoadingOutlined, {
  style: {
    padding: '0px 1px 4px 1px',
    fontSize: '12px',
  },
  spin: true,
});
const indicatorLarge = h(LoadingOutlined, {
  style: {
    padding: '0px 1px 4px 1px',
    fontSize: '36px',
  },
  spin: true,
});
const showDelete = computed(function () {
  return true;
});
const toggleOptimize = () => {
  optimizationCollapsed.value = optimizationCollapsed.value === 0 ? 1 : 0;
  fixWeightCollapsed.value = true;
};
const toggleFixWeight = () => {
  optimizationCollapsed.value = 0;
  fixWeightCollapsed.value = !fixWeightCollapsed.value;
};
const changeCollapsed = (value) => {
  optimizationCollapsed.value = value;
};
const selectLimit = (e) => {
  console.log(e);
  riskLevel.value = e.risk_level;
  max_weight.value = e.max_weight;
  only_my_bank.value = e.only_my_bank;
};
const customFilter = (input, option) => {
  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
var moment = require('moment');
const columnDefs = computed(function () {
  const columns = portfolioStore.getActiveColumns;
  const columnDefs = [];
  if (!authStore.restrictions.includes(Restrictions["OptimizePortfolio"]) && optimizationCollapsed.value > optimizationStatus.COLLAPSED) {
    columnDefs.push({
      cellStyle: { border: "none" },
      pinned: "right",
      field: "min_weight",
      headerName: t("positions_fields.min_weight", "min_weight"),
      headerTooltip: t("positions_fields.min_weight", "min_weight"),
      minWidth: 50,
      maxWidth: 130,
      cellDataType: "number",
      valueParser: function (params) {
        var parsedValue = parseFloat(params.newValue);
        if (isNaN(parsedValue)) {
          return null;
        } else {
          if (parsedValue < 0) {
            return 0;
          } else if (parsedValue > 100) {
            return 100;
          } else {
            return parsedValue;
          }
        }
      },
      valueSetter: function (params) {
        params.data.min_weight = params.newValue / 100;
        return true;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return n(params.value, "percent", {
          minimumFractionDigits: 2,
        });
      },
      editable: true,
    });

    columnDefs.push({
      cellStyle: { border: "none" },
      pinned: "right",
      field: "max_weight",
      headerName: t("positions_fields.max_weight", "max_weight"),
      headerTooltip: t("positions_fields.max_weight", "max_weight"),
      minWidth: 50,
      maxWidth: 130,
      cellDataType: "number",
      valueParser: function (params) {
        var parsedValue = parseFloat(params.newValue);
        if (isNaN(parsedValue)) {
          return null;
        } else {
          if (parsedValue < 0) {
            return 0;
          } else if (parsedValue > 100) {
            return 100;
          } else {
            return parsedValue;
          }
        }
      },
      valueSetter: function (params) {
        params.data.max_weight = params.newValue / 100;
        return true;
      },
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return n(params.value, "percent", {
          minimumFractionDigits: 2,
        });
      },
      editable: true,
    });
  }
  const rowData = portfolioStore.getPositionsTable;
  columns.forEach((column) => {
    let tooltip = t(`header_tooltips.${column.key}`, column.key);
    if (tooltip === column.key) {
      tooltip = t(`positions_fields.${column.key}`, column.key);
    }
    let isColumnEmpty = true;
    for (let i = 0; i < rowData?.length; i ++) {
      const cell = rowData[i][column.key];
      if (cell !== '' && cell !== undefined && cell !== null) {
        isColumnEmpty = false;
        break ;
      }
    }
    const newColumnDef = {
      field: column.key,
      headerName: t(`positions_fields.${column.key}`, column.key),
      headerTooltip: tooltip,
      minWidth: column.key === "name" ? 250 : 150,
      hide: isColumnEmpty && rowData?.length > 0,
      cellDataType: ["decimal", "percent"].includes(column.format.format)
        ? "number"
        : ["bool", "boolean"].includes(column.format.format)
          ? "bool" :
            "text",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        if (["decimal", "percent"].includes(column.format.format)) {
          const decimalQty = column.format.decimalQty || 0;
          return n(params.value, column.format.format, {
            minimumFractionDigits: decimalQty,
          });
        } else if (column.format.format === "boolean") {
          return params.value ? 
            t("portfolio_overview.columns.true", true) :
            t("portfolio_overview.columns.false", false);
        } else {
          const isValid = moment(params.value, moment.ISO_8601, true).isValid();

          if (isValid && column.key !== "name"){
          const date = new Date(params.value);
            return d(date, 'short');
          }
          if (column.key === 'fund_type') {
            const tranlsationKey = `search_funds.filter_criteria_category.assets_type.${params.value}`;
            if (te(tranlsationKey)) {
              return t(tranlsationKey);
            } else {
              return "";
            }
          }
        }
        return params.value;
      },
    };
    if (column.format.format === "percent") {
      newColumnDef["filterParams"] = {
        numberParser: (value) => {
          return !value ? null : parseFloat(value) / 100;
        },
      }
    }
    if (authStore.config["columns_as_index"].includes(column.key)) {
      newColumnDef["pinned"] = "left";
      if (window.innerWidth < 768) {
          newColumnDef["minWidth"] = 120;
          newColumnDef["maxWidth"] = 120;
      }
    }
    if (column.key === "name") {
      newColumnDef["tooltipField"] = "name";
    }
    columnDefs.push(newColumnDef);
    if (column.key === 'weight') {
      const table = portfolioStore.getPositionsTable.length
        ? portfolioStore.getPositionsTable
        : [];
      let ports = [];
      const weightFormat = portfolioStore.getColumnFormat("weight");
      const port_prefix = authStore.config['parameters']['prefix_new_portfolios'];
      table.forEach((row) => {
        ports = ports.concat(
          Object.keys(row).filter((key) => key.startsWith(port_prefix))
        );
      });
      ports = [...new Set(ports)];
      ports.forEach((key, index) => {
        const headerName =
          t(`positions_fields.${port_prefix}`, port_prefix) + `${index + 1}`;
        columnDefs.push({
          field: key,
          headerName: headerName,
          headerTooltip: headerName,
          minWidth: 150,
          cellDataType: "number",
          valueFormatter: (params) => {
            if (params.value == null) {
              return "";
            }
            if (weightFormat) {
              const format = weightFormat;
              return n(params.value, format["format"], {
                minimumFractionDigits: format["decimalQty"],
              });
            }
            return n(params.value, "decimal", {
              minimumFractionDigits: 2,
            });
          },
        });
      });
      columnDefs.push({
        field: 'fix_weight',
        headerName: t(`positions_fields.${port_prefix}`, port_prefix) + `${ports.length + 1}`,
        headerTooltip: t(`positions_fields.${port_prefix}`, port_prefix) + `${ports.length + 1}`,
        minWidth: 150,
        cellDataType: "number",
        editable: true,
        hide: fixWeightCollapsed.value,
        valueParser: function (params) {
          const parsedValue = parseFloat(params.newValue);
          if (isNaN(parsedValue)) {
            return null;
          } else {
            if (parsedValue < 0) {
              return 0;
            } else if (parsedValue > 100) {
              return 100;
            } else {
              return parsedValue;
            }
          }
        },
        valueSetter: function (params) {
          params.data.fix_weight = params.newValue / 100;
          return true;
        },
        valueFormatter: (params) => {
          if (params.value == null) {
            return "0%";
          }
          if (weightFormat) {
            const format = weightFormat;
            return n(params.value, format["format"], {
              minimumFractionDigits: format["decimalQty"],
            });
          }
          return n(params.value, "decimal", {
            minimumFractionDigits: 2,
          });
        },
      });
    }
  });
  return columnDefs;
});
const showColumnsModal = function (table) {
  visibleColumnModal.value = true;
  tableType.value = table;
};
const hideColumnsModal = function () {
  visibleColumnModal.value = false;
};
const printPdf = async function () {
  try {
    printing.value = true;
    visibleReport.value = false;
    const selectedData = portfolioStore.getPositionsTable;
    pdfprinter.value?.printReport(
      getDatasets.value,
      "portfolio",
      columnDefs.value,
      selectedData,
      "portfolio"
    );
  } catch (err) {
    console.log(err);
    printing.value = false;
  }
};
const generatePDF = async function (selectedData) {
  const reports = await basketStore.loadReports(
    selectedData.map((row) => row.isin_tkr)
  );
  printGraph.value = await basketStore.generateGraph(selectedData.map((e) => e.isin_tkr_id));
  setTimeout(() => {
    pdfprinter.value.printReport(
      reports,
      "portfolio",
      columnDefs.value,
      selectedData,
      "asset"
    );
  }, 3000);
};
const printAsset = async function () {
  try {
    printing.value = true;
    visibleReport.value = false;
    const selectedRows = tableRef.value?.getSelectedRows();
    const selectedData =
      selectedRows.length > 0 ? selectedRows : portfolioStore.getPositionsTable;
    const fundAssets = selectedData.filter(item => item?.type === 'Fund');
    if (fundAssets?.length === 0) {
      printing.value = false;
      message.warning(t("message.ask_funds"));
    } else if (fundAssets?.length < selectedData?.length) {
      message.warning(t("message.generate_pdf_with_only_funds"));
      await generatePDF(fundAssets);
    } else {
      await generatePDF(fundAssets);
    }

  } catch (err) {
    console.log(err);
    printing.value = false;
  }
};

const hasDownloaded = (result) => {
  if (result) {
    printing.value = false;
  } else {
    message.warning(t("message.failed_pdf"));
    printing.value = false;
  }
};
const calculateFixWeightSum = () => {
  const rowData = portfolioStore.getPositionsTable || [];
  let sum = 0;
  for (let i = 0; i < rowData.length; i ++) {
    if (!isNaN(rowData[i]['fix_weight'])) {
      sum += rowData[i]['fix_weight'];
    }
  }
  fixWeightSum.value = parseFloat(sum.toFixed(3));
};
const releaseOrDiscard = (action) => {
  const selectedData = tableRef.value?.getSelectedRows();
  if (selectedData?.length > 0) {
    portfolioStore.selectedPortfolio = {
      ...portfolioStore.selectedPortfolio,
      portfolio: portfolioStore.getPositionsTable.map((e) => {
        if (selectedData.find(item => item?.isin_tkr_id === e?.isin_tkr_id)) {
          console.log(action === 'release' ?  (max_weight.value != null   ? max_weight.value  : authStore.config["max_weight"]): 0);
          return {
            ...e,
            min_weight: 0,
            max_weight: action === 'release' ?  (max_weight.value != null   ? max_weight.value  : authStore.config["max_weight"]): 0,
          };
        } else {
          return e;
        }
      })
    };
    disableReleaseOrDiscard.value = true;
    selectAll.value = false;
  }
};
const selectionChanged = function (e) {
  enableActions.value = e.api.getSelectedRows().length > 0;
  disableReleaseOrDiscard.value = e.api.getSelectedRows().length === 0;
};
const onColumnMoved = function (e) {
  const portPrefix = authStore.config['parameters']['prefix_new_portfolios'];
  if (e.column?.colId.includes(portPrefix)) {
    const weightColumn = e.columnApi.getColumn('weight');
    e.columnApi.moveColumn(weightColumn, e.toIndex);
  }
  portfolioStore.activeColumns = e.columnApi.getColumnState().map(item => item.colId);
};
const onColumnRemoved = function (e) {
  portfolioStore.deleteActiveColumn(e.column?.colId);
};
const clearSelectedBasket = function () {
  authStore.logAction('remove_positions');
  if (tableRef.value?.getSelectedRows().some(item => {
    const keys = Object.keys(item).filter(key => !!item[key]);
    return keys.some(key => key.startsWith(authStore?.config?.parameters?.prefix_new_portfolios) || key === 'weight');
  })) {
    message.warning(t('message.unable_delete'));
  }

  tableRef.value?.getSelectedRows().forEach((row) => {
    const keys = Object.keys(row).filter(key => !!row[key]);
    if (!keys.some(key => key.startsWith(authStore?.config?.parameters?.prefix_new_portfolios) || key === 'weight')) {
      portfolioStore.removePosition(row.isin_tkr_id);
    }
  });
  enableActions.value = false;
  
};
const clearBasket = async function () {
  if (portfolioStore.getPositionsTable.some(item => {
    const keys = Object.keys(item).filter(key => !!item[key]);
    return keys.some(key => key.startsWith(authStore?.config?.parameters?.prefix_new_portfolios) || key === 'weight');
  })) {
    message.warning(t('message.unable_delete'));
  } else {
    await portfolioStore.deleteAllPositions();
  }
};
const addToBasket = async () => {
  const selectedRows = tableRef.value?.getSelectedRows();
  const selectedData =
    selectedRows.length > 0 ? selectedRows : portfolioStore.getPositionsTable;
  const isinArray = selectedData.map(item => item.isin_tkr_id);
  const result = portfolioStore?.getPositionsTable?.filter(item => isinArray.includes(item.isin_tkr_id));
  await basketStore.addAsset(result);
  enableActions.value = false;
};

const getDatasets = computed(() => {
  return [
    ...portfolioStore.getCompositionsVisible(true),
    ...portfolioStore.getRatios,
  ];
});

const handleOptimize = async () => {
  const isSomeNoFunds = portfolioStore.getPositionsTable?.some(item => item.type !== 'Fund');
  const isCompositionLimits = portfolioLimitsStore.compositions?.length > 0;
  if (isSomeNoFunds && isCompositionLimits) {
    message.warning(t('portfolio_overview.composition_limits_with_non_funds'));
  } else {
    await portfolioStore.optimizePortfolio();
  }
};

const handleFixWeights = async () => {
  const table = portfolioStore.getPositionsTable.length
    ? portfolioStore.getPositionsTable
    : [];
  let ports = [];
  const port_prefix = authStore.config['parameters']['prefix_new_portfolios'];
  table.forEach((row) => {
    ports = ports.concat(
      Object.keys(row).filter((key) => key.startsWith(port_prefix))
    );
  });
  ports = [...new Set(ports)];
  await portfolioStore.fixWeights(`${port_prefix}${ports.length + 1}`);
  fixWeightCollapsed.value = true;
};

watch(
  () => riskLevel.value,
  (newRisk) => {
    if (newRisk) {
      portfolioStore.riskLevel = newRisk;
      portfolioStore.manualRiskLevel = null;
      manualRiskLevel.value = null;
    }
  }
);
watch(
  () => new_candidates.value,
  (new_new_candidates) => {
    portfolioStore.new_candidates = new_new_candidates;
  }
);
watch(
  () => max_weight.value,
  (new_max_weight) => {
    portfolioStore.max_weight = new_max_weight;
  }
);
watch(
  () => only_my_bank.value,
  (new_only_my_bank) => {
    portfolioStore.only_my_bank = new_only_my_bank;
  }
);
watch(
() => manualRiskLevel.value,
(newRisk) => {
  if (newRisk) {
    portfolioStore.manualRiskLevel = newRisk;
    portfolioStore.riskLevel = null;
    riskLevel.value = null;
  }
}
);
const updateCookieCols = (activeCols) => {
  Cookies.set(
    "portfolio-active-columns",
    JSON.stringify(activeCols.map((col) => col.key))
  );
  availableSelect.value =
    activeCols.length <
    portfolioStore.allPortfolioCols.length;
};
watch(
  () => portfolioStore.getActiveColumns,
  (newActiveColumns) => {
    updateCookieCols(newActiveColumns);
  }
);
onMounted(async () => {
  riskLevel.value = portfolioLimitsStore.apply_risk;
  await portfolioStore.reloadAssets();
  portfolioStore.activeColumns = [];
  portfolioStore.columns = [];
  portfolioStore.populateColumns();
  portfolioStore.new_candidates = false;
  const cookieData = JSON.parse(
    Cookies.get("portfolio-active-columns") || "[]"
  );
  if (cookieData.length > 0) {
    portfolioStore.activeColumns = cookieData;
  }
  updateCookieCols(portfolioStore.getActiveColumns);
  calculateFixWeightSum();
});
</script>

<style scoped lang="scss">
@import "~@/static/css/colors.scss";

:deep(.ag-root-wrapper) {
  height: 100%;
}

:deep(.ag-header-cell-text) {
  color: #49c5b1;
  font-size: 14px;
  font-weight: 500;
}
.collapse-label-btn {
  padding: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  color: $text-light-color;
  width: fit-content;
  position: relative;
  & label {
    cursor: pointer;
    font-size: 14px;
  }
  & .control-info {
    position: absolute;
    left: -12px;
    top: -8px;
  }
}
.report-type-button {
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  &:hover {
    background: rgba(169, 166, 166, 0.1);
  }
}
.optimize-button-placement {
  // margin-right: -40px;
  margin-top: 1px;

  @media only screen and (max-width: 1149px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
}
.table-btn-group {
  padding: 24px 16px 8px 16px;
  //padding: 24px 16px 12px 16px;
}

.mobile-table-length {
  display: none;
}
@media only screen and (max-width: 640px) {
  .table-btn-group {
    display: none;
  }
  .optimize-control {
    display: none;
  }
  .mobile-table-length {
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    font-size: 14px;
    color: #02B388;
    font-weight: 500;
  }
}

.portfolio-mobile-data {
  position: relative;
  .loading-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 32px !important;
  }
  @media only screen and (max-width: 1149px) {
    overflow-x: auto;
  }
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-item {
  display: flex;
  justify-content: center;
  width: 100%;
}

:deep(.native-select) {
  border: 1px black solid;
  padding: 0 !important;
  margin: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
    padding-left: 10px !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
.paginationSelectorRow {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px 0;
  font-size: 14px;
  & label {
    color: $text-light-color;
  }
  & select {
    color: $text-light-color;
    outline: none;
    border: 1px solid $text-light-color;
    border-radius: 12px;
    padding: 1px;
    background: transparent;
  }
}
.label-button {
  font-size: 14px;
  text-decoration: underline;
  margin: 4px 36px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: $text-light-color;
  }
}

:deep(.ant-checkbox-wrapper) {
  span {
    color: $text-light-color !important;
  }
}
:deep(.ant-input-number) {
  width: 70px;
  border-color: black !important;
  display: flex;
  align-items: center;
  box-shadow: none !important;
}

:deep(.ant-input-number-input) {
  height: 100% !important;
  padding-top: 7px;
}

.column-btn {
  background-color: #adacaf !important;
}
.column-btn-span {
  color: #adacaf !important;
  &:hover {
    color: #848287 !important;
  }
}
.collapssible-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: center;
  padding: 4px 16px;
  border: 1px solid $text-light-color;
}
.weight-set-btn {
  font-size: 14px;
  text-decoration: underline;
  padding: 0 2px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}
:deep(.ag-row):hover {
  background-color: #F2F2F2;
}
</style>
<style>
.print-graph-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  transform: translateY(150%);
  .highcharts-navigator, .highcharts-scrollbar, .highcharts-range-selector-buttons, .highcharts-input-group {
    display: none !important;
  }
}
</style>
