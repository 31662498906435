<template>
    <div style="background: #f2f2f2; position: relative;">
      <div class="table-btn-group">
        <a-space class="w-100 justify-content-between">
          <a-space>
            <a-switch size="large" class="mb-1" style="margin-right: 12px;" v-model:checked="selectAll" />
            <!-- <label
              class="label-button"
              @click="rebalance()"
            >
              {{ $t("portfolio_control.rebalancer") }}
            </label> -->
            <label
              class="label-button"
              @click="review()"
            >
              {{ $t("portfolio_control.review") }}
            </label>
            <label
              class="label-button"
              @click="printReport()"
            >
              {{ $t("funds_printer.portfolio_report") }}
              <a-spin
                size="small"
                class="mt-2"
                style="margin-left: 4px;"
                v-if="printing"
              ></a-spin>
            </label>
          </a-space>
          <a-space class="align-items-center">
            <div class="paginationSelectorRow pb-1">
              <label>
                {{ portfolioControlStore?.getPortfoliosTable?.length ?? 0 }} {{ t('generic.titles.table_length') }}
              </label>
              <select v-model="portfolioControlStore.selectedPageSize" class="native_page_select">
                <option
                  v-for="pageSize in paginationPageSizeOptions"
                  v-bind:key="pageSize"
                  :value="pageSize"
                >
                  {{ pageSize }}
                </option>
              </select>
            </div>    
          </a-space>  
        </a-space>
      </div>
      <div class="mobile-table-length">
        <label>
          {{ portfolioControlStore?.getPortfoliosTable?.length ?? 0 }} {{ t('generic.titles.table_length') }}
        </label>
      </div>
      <div class="legend-info">
        <a-popover
          v-model:visible="visibleLegend"
          trigger="click"
          placement="top"
        >
          <template #content>
            <color-legend :config="authStore.config?.benchmark_comparison_limits"></color-legend>
          </template>
          <div style="display: flex; align-items: center; gap: 4px; cursor: pointer;">
            <wiser-icon icon="info"></wiser-icon>
            <label style="font-size: 14px;">{{ t('portfolio_control.color_legend') }}</label>
          </div>
        </a-popover>
      </div>
      <div class="portfolio-mobile-data">
        <full-table
          ref="tableRef"
          :columnDefs="columnDefs"
          @selection-changed="selectionChanged"
          :rowData="portfolioControlStore.getPortfoliosTable"
          :selectAll="selectAll"
          :pageSize="portfolioControlStore.selectedPageSize ?? 20"
        >
        </full-table>
      </div>
      <div class="loading-container" v-if="loading">
        <a-spin></a-spin>
      </div>
    </div>
    <pdf-printer ref="pdfprinter" @has-downloaded="hasDownloaded">
      <template v-slot:body-pdf>
        <template v-if="portfolioControlStore.selectedPortfolios?.length > 0">
          <portfolio-control-graph></portfolio-control-graph>
        </template>
      </template>
    </pdf-printer>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/AuthStore";
import pdfPrinter from "@/components/pdfPrinter";
import { usePortfolioControlStore } from "@/stores/PortfolioControlStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import FullTable from "@/components/wiseral/FullTable";
import PortfolioControlGraph from "./PortfolioControlGraph.vue";
import moment from "moment";
import { customNumberFormatter } from "../../../utility/helpers";
import { message } from "ant-design-vue";
import { useRouter } from "vue-router";
import ColorLegend from "./components/ColorLegend.vue";
import WiserIcon from "@/components/icons/wiser-icons";

const { t, n, d, locale } = useI18n();
const router = useRouter();
const portfolioStore = usePortfolioStore();
const portfolioControlStore = usePortfolioControlStore();
const authStore = useAuthStore();
const tableRef = ref(null);
const selectAll = ref(false);
const loading = ref(false);
const pdfprinter = ref(null);
const printing = ref(false);
const visibleLegend = ref(false);

let paginationPageSizeOptions = [20, 50, 100, 500];

const getCellColor = (compareValue) => {
  const compareLimits = authStore.config?.benchmark_comparison_limits;
  if (!compareLimits) {
    return 'black';
  }
  let color = 'black';
  for (let i = 0; i < compareLimits?.length ?? 0; i ++) {
    const cl = compareLimits[i];
    if (compareValue < (cl.max / 100) && compareValue >= (cl.min / 100)) {
      color = cl.color;
      break;
    }
  }
  return color;
};

const columnDefs = computed(function () {
    const columnData = portfolioControlStore.getColumns;
    const formats = portfolioControlStore.controlData?.column_format ?? {};
    return columnData.map(column => {
      const newColumnDef = {
        field: column,
        headerName: t(`positions_fields.${column}`, column),
        headerTooltip: t(`header_tooltips.${column}`, column),
        minWidth: column === "name" ? 250 : 120,
        cellDataType: ["decimal", "percent"].includes(formats[column]?.format)
          ? "number"
          : "text",
        cellStyle: (params) => {
          const col = params.column.colId;
          const row = params.data;
          const compareValue = row?.['compared']?.[col];
          if (!isNaN(compareValue)) {
            if (["percent"].includes(formats[column]?.format)) {
              return { color: getCellColor(compareValue) };
            } else {
              return null;
            }
          } else {
            return null;
          }
        },
        valueFormatter: (params) => {
          if (params.value == null) {
            return "";
          }
          if (["decimal", "percent", "currency"].includes(formats[column]?.format)) {
            return customNumberFormatter(n, params.value, formats[column]?.format, formats[column]?.decimalQty ?? 2, locale.value);
          } else if (formats[column]?.format === "boolean") {
            return params.value ? 
              t("portfolio_overview.columns.true", true) :
              t("portfolio_overview.columns.false", false);
          } else if (column === 'risk') {
            const risk = Object.keys(authStore?.config["risk_levels"] ?? {})?.[params.value];
            return t(`generic.risk_options.${risk}`, risk);
          } else if (!isNaN(params.value)) {
            return n(params.value, 'decimal', {
              minimumFractionDigits: 2,
            });
          } else {
            const isValid = moment(params.value, moment.ISO_8601, true).isValid();
            
            if (isValid && column !== "name"){
              const date = new Date(params.value);
              return d(date, 'short');
            }
          }
          return params.value;
        },
      };
      if (column === 'name') {
        newColumnDef['pinned'] = 'left';
      }
      return newColumnDef;
    });
});

const review = async () => {
    try  {
      const selectedData = tableRef.value?.getSelectedRows();
      if (selectedData?.length === 1) {
        const ids = selectedData.map(e => e?.id);
        loading.value = true;
        await portfolioStore.loadPortfolios(ids);
        loading.value = false;
        router.push({ name: "portfolio-overview-ids", params: { ids: ids.join(',') } });
      } else {
        message.warning(t("portfolio_control.select_one_fund"));
      }
    } catch (e) {
      console.log("err: ", e);
      loading.value = false;
    }
};

// const rebalance = async () => {
//     try {
//       const selectedData = tableRef.value?.getSelectedRows();
//       if (selectedData?.length > 0) {
//         const ids = selectedData.map(e => e?.id);
//         loading.value = true;
//         await portfolioControlStore.rebalance(ids);
//         loading.value = false;
//       } else {
//         message.warning(t("portfolio_control.select_any_fund"));
//       }
//     } catch (e) {
//       console.log("err: ", e);
//       loading.value = false;
//     }
// };

const selectionChanged = () => {
  portfolioControlStore.selectedPortfolios = tableRef.value?.getSelectedRows() ?? [];
};

const getDatasets = computed(() => {
  return [
    ...portfolioStore.getCompositionsVisible(true),
    ...portfolioStore.getRatios,
  ];
});

const printReport = async () => {
  try  {
    const selectedData = tableRef.value?.getSelectedRows();
    if (selectedData?.length === 1) {
      printing.value = true;
      const ids = selectedData.map(e => e?.id);
      await portfolioStore.loadPortfolios(ids);

      const assetTableColumnDefs = authStore.config["portfolio_column_order"]?.map(column => {
        const colDef = {
          field: column,
          headerName: t(`positions_fields.${column}`, column),
        };
        return colDef;
      });

      pdfprinter.value?.printReport(
        getDatasets.value,
        "portfolio",
        assetTableColumnDefs,
        portfolioStore.getPositionsTable,
        "portfolio"
      );
    } else {
      message.warning(t("portfolio_control.select_one_fund"));
    }
  } catch (e) {
    console.log("err: ", e);
    printing.value = false;
  }
};

const hasDownloaded = (result) => {
  if (result) {
    printing.value = false;
  } else {
    message.warning(t("message.failed_pdf"));
    printing.value = false;
  }
}

onMounted(() => {
  portfolioControlStore.selectedPortfolios = [];
});
</script>

<style scoped lang="scss">
  @import "~@/static/css/colors.scss";
  :deep(.ag-root-wrapper) {
    height: 100%;
  }

  :deep(.ag-header-cell-text) {
    color: #49c5b1;
    font-size: 14px;
    font-weight: 500;
  }
  .collapse-label-btn {
    padding: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    color: $text-light-color;
    width: fit-content;
    & label {
      cursor: pointer;
      font-size: 14px;
    }
  }
  .table-btn-group {
    padding: 24px 16px 8px 16px;
  }
  .mobile-table-length {
    display: none;
  }
  @media only screen and (max-width: 640px) {
    .table-btn-group {
      display: none;
    }

    .mobile-table-length {
      display: flex;
      justify-content: flex-end;
      padding: 8px;
      font-size: 14px;
      color: #02B388;
      font-weight: 500;
    }
  }

  .portfolio-mobile-data {
    @media only screen and (max-width: 1149px) {
      overflow-x: auto;
    }
  }

  .table-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .table-item {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  :deep(.native-select) {
    border: 1px black solid;
    padding: 0 !important;
    margin: 0 !important;
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
      padding: 0 !important;
      padding-left: 10px !important;
    }

    &.ant-select-open {
      .ant-select-selector {
        border: none !important;
        height: 100% !important;
      }
    }
  }
  .paginationSelectorRow {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin: 10px 0;
    font-size: 14px;
    & label {
      color: $text-light-color;
    }
    & select {
      color: $text-light-color;
      outline: none;
      border: 1px solid $text-light-color;
      border-radius: 12px;
      padding: 1px;
      background: transparent;
    }
  }
  .label-button {
    font-size: 14px;
    text-decoration: underline;
    margin: 4px 36px 8px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      color: $text-light-color;
    }
  }

  :deep(.ant-checkbox-wrapper) {
    span {
      color: $text-light-color !important;
    }
  }
  :deep(.ant-input-number) {
    width: 70px;
    border-color: black !important;
    display: flex;
    align-items: center;
    box-shadow: none !important;
  }

  :deep(.ant-input-number-input) {
    height: 100% !important;
    padding-top: 7px;
  }
  .column-btn {
    background-color: #adacaf !important;
  }
  .column-btn-span {
    color: #adacaf !important;
    &:hover {
      color: #848287 !important;
    }
  }
  :deep(.ag-row):hover {
    background-color: #F2F2F2;
  }
  .loading-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(100, 100, 100, 0.2);
  }

  .legend-info {
    position: absolute;
    left: 1.5rem;
    bottom: 2rem;
    z-index: 100;
  }
</style>