<template>
  <div class="summary-wrapper">
    <template v-if="getResume[0].values.length === 1">
      <div class="cards-wrapper-desktop" :style="{ width: `${getResume.length * 25}%` }">
        <a-col :xs="24 / getResume.length" v-for="(r, i) in getResume" v-bind:key="i">
          <a-tooltip :title="$t(`portfolio_overview.columns.tooltips.${r.name}`, r.name)">
            <sd-cards
              headless
              :title="$t(`portfolio_overview.columns.${r.name}`, r.name)"
              style="cursor: pointer;"
              :style="{'border-right': (i + 1) === getResume.length ? 'none' : '1px solid #02B388'}"
            >
              <div v-for="(p, pi) in r.values" v-bind:key="pi">
                <value-printer :value="r" :position="p" />
              </div>
            </sd-cards>
          </a-tooltip>
        </a-col>
      </div>
      <div class="cards-wrapper-mobile">
        <a-col :xs="24" v-for="(r, i) in getResume" v-bind:key="i" class="mobile-summary-item">
          <a-tooltip :title="$t(`portfolio_overview.columns.tooltips.${r.name}`, r.name)">
            <sd-cards
              headless
              :title="$t(`portfolio_overview.columns.${r.name}`, r.name)"
            >
              <div v-for="(p, pi) in r.values" v-bind:key="pi">
                <value-printer :value="r" :position="p" />
              </div>
            </sd-cards>
          </a-tooltip>
        </a-col>
      </div>
    </template>
    <div v-else>
      <a-row>
        <a-col :xs="24" :md="16" style="overflow-x: auto; max-width: 100%">
          <table-printer
            :datasets="getTableData"
            :sortable="false"
          ></table-printer>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import valuePrinter from "@/components/wiseral/valuePrinter.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import tablePrinter from "@/components/wiseral/tablePrinter.vue";

const authStore = useAuthStore();
const portfolioStore = usePortfolioStore();

const getResume = computed(() => {
  let dataArray = [];
  const summary = portfolioStore.getSummary;
  const keys = Object.keys(summary);

  const visible_cash = authStore.config?.visible_cash;
  const filteredKeys = keys.filter(key => visible_cash || key !== 'CASH');

  filteredKeys.forEach((key) => {
    const element = summary[key];
    dataArray.push({
      name: key,
      format: portfolioStore.getColumnFormat(key)?.format,
      decimalQty: portfolioStore.getColumnFormat(key)?.decimalQty,
      values: Object.entries(element).map(([key, value]) => ({
        id: key,
        title: key,
        value: value,
      })),
    });
  });

  return dataArray;
});

const getTableData = computed(() => {
  const columns = getResume.value.map(e => e?.name);
  const formats = {};
  getResume.value.map(e => {
    formats[e.name] = {
      format: e?.format,
      decimalQty: e?.decimalQty
    };
  });
  const data = getResume.value[0]?.values?.map((e, index) => {
    const data = {
      key: e?.id
    };
    getResume.value.map(value => {
      data[value?.name] = value?.values[index]?.value;
    });
    return data;
  });
  return [{
    title: 'summary',
    columns,
    formats,
    data
  }];
});
</script>
<style scoped lang="scss">
:deep(h1) {
  color: #02B388;
}
.cards-wrapper-desktop {
  display: flex;
  border: 1px solid #02B388;
  padding: 16px 0px;
  margin-bottom: 16px;
  box-shadow: 0px 3px 6px #00000029;
}
.cards-wrapper-mobile {
  display: none;
  width: 100%;
  .mobile-summary-item {
    margin-bottom: 8px;
    padding: 16px 0;
    border: 1px solid #02B388;
    box-shadow: 0px 3px 6px #00000029;
  }
}
:deep(.ant-card) {
  background: none;
  margin: 0;
}
:deep(.ant-card-body) {
  box-shadow: none;
  background: none;
  padding: 0 7% !important;
  h1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .cards-wrapper-mobile {
    display: block;
    padding: 0 16px;
  }
  .cards-wrapper-desktop {
    display: none;
  }
  .summary-wrapper {
    overflow-x: auto;
    display: flex;
    justify-content: center;
  }
  .ant-card-body {
    display: none;
  }
}
</style>
