<template>
  <template v-if="!props.datasets.data">
  </template>
  <a-col :xs="24" :md="18" v-else-if="props.datasets.data?.length === 1">
    <p class="region-title">{{ $t(`portfolio_overview.headings.chart_regions`, "Regions") }}</p>
    <div class="pie-chart-wrapper">
      <highcharts
        class="hc"
        :options="chartOptions"
        ref="chart"
        :style="{ background: '#f2f2f2' }"
      ></highcharts>
    </div>
  </a-col>
  <a-col :xs="24" style="padding: 1.5rem;" v-else>
    <p style="font-size: 20px; color: #004851; font-weight: bold; border-bottom: 1px solid gray;">{{ t('portfolio_overview.headings.chart_regions') }}</p>
    <a-row class="region-list-wrapper">
      <a-col :xs="24" :md="8" :lg="6" :xl="4" class="region-legends">
        <div
          :key="`${col}-${index}`"
          v-for="(col, index) in props.datasets.columns.filter(e => e !== 'Unkown')"
          class="region-item"
          :class="{ 'inactive': hiddenColumns.includes(col === 'Total' ? 'non_equity' : col) }"
          @click="clickLegendItem(col === 'Total' ? 'non_equity' : col)"
        >
          <div style="width: 12px; height: 12px;" :style="{ background: colors[index % colors.length] }"></div>
          {{ col === 'Total' ? t(`positions_fields.non_equity`, 'No Equity') : t(`positions_fields.${col}`, col) }}
        </div>
      </a-col>
      <a-col :xs="24" :md="16" :lg="18" :xl="20">
        <div class="region-bar-list-wrapper">
          <div
            class="region-data"
            :key="`${row}-${rowIndex}`"
            v-for="(row, rowIndex) in barsData.rows"
          >
            <a-tooltip
              class="region-item-tooltip"
              placement="right"
              :title="t(`positions_fields.${col.key}`, col.key) + ' - ' + $n(row[col.key], 'percent', {
                minimumFractionDigits: 2,
              } )"
              :key="index"
              v-for="(col, index) in barsData.columns"
            >
              <div
                class="region-data-item"
                :style="{ background: col.color, height: `${row[col.key] * 300}px` }"
              >
                <p
                  style="region-data-value"
                  v-if="(row[col.key] * 300) > 7"
                >{{ n(row[col.key], 'percent', {
                  minimumFractionDigits: 2,
                } ) }}</p> 
              </div>
            </a-tooltip>
            <p class="regoin-port-name">{{ props.datasets?.data?.[rowIndex]?.['key'] ?? '' }}</p>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-col>
</template>
<script setup>
import { computed, defineProps, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/AuthStore";

const { t, n } = useI18n();
const authStore = useAuthStore();

const props = defineProps({
  datasets: {
    type: Object,
    default: function () {
      return {};
    }
  }
});

const chart = ref(null);
const colors = authStore.config['region_colors'];
const hiddenColumns = ref([]);

const barsData = computed(() => {
  if (!props.datasets.data) {
    return {};
  }
  if (props.datasets.data) {
    const rows = props.datasets?.data?.map(row => {
      const newRow = {
        'non_equity': 1 - row['Total'] ?? 0
      };
      let sum = hiddenColumns.value.includes('non_equity') ? 0 : 1 - row['Total'];
      for (let i = 0; i < props.datasets?.columns?.length ?? 0; i ++) {
        const key = props.datasets?.columns[i];
        if (
          key !== 'Total' &&
          key !== 'key' &&
          key !== 'Unkown' &&
          !hiddenColumns.value.includes(key)
        ) {
          newRow[key] = row[key];
          sum += row[key];
        }
      }
      const updatedRow = {};
      const keys = Object.keys(newRow);
      for (let i = 0; i < keys.length; i ++) {
        updatedRow[keys[i]] = newRow[keys[i]] / sum;
      }
      return updatedRow;
    }) || [];
    const columns = [ ...props.datasets?.columns?.filter(col => col !== 'Total' && col !== 'key' && col !== 'Unkown') || [], 'non_equity']
      .map((col, index) => ({ key: col, color: colors[index] })).
      filter(e => !hiddenColumns.value.includes(e.key));
    return {
      rows,
      columns
    };
  }
  return {
    rows: [],
    columns: []
  }
});

const clickLegendItem = (col) => {
  if (hiddenColumns.value.includes(col)) {
    hiddenColumns.value = hiddenColumns.value.filter(e => e !== col);
  } else {
    hiddenColumns.value.push(col);
  }
};

const series = computed(() => {
  if (!props.datasets.data) {
    return [];
  }
  const actualData = Object.keys(props.datasets.data[0]).filter(item => item !== 'key' && item !== 'Total').map(key => {
    return {
      name: t(`positions_fields.${key}`, key),
      y: props.datasets.data[0][key] * 100
    };
  });
  return [ ...actualData, {
    name: t(`positions_fields.non_equity`, 'Non Equity'),
    y: (1 - props.datasets.data[0]['Total']) * 100
  } ];
});

const chartOptions = computed(() => {
  return {
    colors: colors,
    chart: {
      type: 'pie'
    },
    tooltip: {
      valueSuffix: '%'
    },
    legend: {
      layout: window.innerWidth > 768 ? 'vertical' : 'horizontal',
      align: window.innerWidth > 768 ? 'right' : 'left',
      verticalAlign: window.innerWidth > 768 ? 'middle' : 'bottom',
      itemMarginTop: 4,
      itemMarginBottom: 4,
      useHTML: true,
      enabled: true,
      title: {
        text: window.innerWidth > 768 ? `${t(`portfolio_overview.headings.chart_regions`, "Regions")}` : '',
        style: {
          fontWeight: 'bold',
          fontSize: '16px',
          fontFamily: 'Helvetica',
          color: '#06a07d',
        }
      },
      labelFormatter: function() {
        return `
        <div style="display: flex; align-items: center; gap: 4px; font-family: "Helvetica", sans-serif;">
            <div style="width: 10px; height: 10px; background: ${this.color}"></div>
            <p style="font-size: 13px; font-weight: 500; margin-bottom: 0;">${this.name}</p>
          </div>
        `;
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: window.innerWidth > 768,
          distance: -90,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '16px',
            textOutline: 'black',
            opacity: 0.7
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 5
          }
        },
        showInLegend: true
      }
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: series.value
      }
    ]
  }
});
const handleResize = () => {
  chartOptions.value.legend.layout = window.innerWidth > 768 ? 'vertical' : 'horizontal';
  chartOptions.value.legend.align = window.innerWidth > 768 ? 'right' : 'left';
  chartOptions.value.legend.verticalAlign = window.innerWidth > 768 ? 'middle' : 'bottom';
  chartOptions.value.legend.title.text = window.innerWidth > 768 ? `${t(`portfolio_overview.headings.chart_regions`, "Regions")}` : '';
  chart.value?.chart.update(chartOptions.value);
};
onMounted(() => {
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>
<style scoped lang="scss">

:deep(.highcharts-background) {
  fill: #F8F8F8;
}

:deep(.highcharts-credits) {
  display: none;
}

:deep(.highcharts-title) {
  display: none;
}

:deep(.highcharts-series-group) {
  transform-origin: 50% 50%;
  transform: scale(0.8);
  @media only screen and (max-width: 480px){
    transform-origin: 50% 25%;
    transform: scale(1.2);
  }
}

:deep(.highcharts-legend-item) {
  & rect {
    display: none;
  }
}

:deep(.highcharts-legend-title) {
  left: 24px !important;
}

.pie-chart-wrapper {
  width: 640px;
}

.region-item {
  padding-top: 4px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    color: lightgray;
  }
  &.inactive {
    color: lightgray;
  }
}
.region-data {
  padding: 0 2rem;
  p {
    font-size: 13px;
    font-weight: bold;
  }
}
.region-data-item {
  position: relative;
  width: 120px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  transition: all 500ms ease-in-out;
  &:hover {
    opacity: 0.75;
  }
  p {
    position: absolute;
    left: 105%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.regoin-port-name {
  text-align: center;
  padding-top: 4px;
}
.region-list-wrapper {
  overflow-x: auto;
  padding-top: 2rem;
}
.region-bar-list-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 3rem;
  height: 100%;
}
.region-title {
  display: none;
}
@media only screen and (max-width: 950px){
  .pie-chart-wrapper {
    width: 480px;
  }
}
@media only screen and (max-width: 768px){
  .pie-chart-wrapper {
    width: 100vw;
  }

  .region-title {
    margin: 16px 0;
    background-color: #004851;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    color: white;
  }
  
  .region-list-wrapper {
    justify-content: center;
  }

  .region-legends {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }

  .region-bar-list-wrapper {
    gap: 2.5rem;
    padding-top: 16px;
  }
}
</style>