<template>
  <template v-if="portfolioStore.getNav.length > 0">
    <div class="the-graph">
      <a-row
      class="graph-toolbar"
      >
        <a-row style="gap: 4px; align-items: center; padding: 2px 0;">
          <sdButton
            size="small"
            type="primary"
            class="range-btn"
            :class="{'outline' : portfolioStore?.graphOptions?.rangeSelector?.selected === index}"
            v-for="(btn, index) in rangeButtons"
            :key="index"
            @click="changeRange(index)"
          >
            {{ t(`chart.range.${btn.transKey}`, btn.transKey) }}
          </sdButton>
        </a-row>

        <a-row style="gap: 12px; align-items: center; padding: 2px 0;">
          <sdButton
            size="small"
            :class="currency === 'EUR' ? 'active' : 'inactive'"
            outlined
            class="convert-btn"
            @click="setCurrency('EUR')"
          >
            EUR
          </sdButton>
          <sdButton
            size="small"
            :class="!currency ? 'active' : 'inactive'"
            outlined
            class="convert-btn"
            @click="setCurrency(null)"
          >
            {{ $t("funds_printer.neutral") }}
          </sdButton>
          <sdButton
            size="small"
            :class="currency === 'USD' ? 'active' : 'inactive'"
            outlined
            class="convert-btn"
            @click="setCurrency('USD')"
          >
            USD
          </sdButton>
        </a-row>
        <a-row style="align-items: center; padding: 2px 0;">
          <a-date-picker v-model:value="startDate" :format="'YYYY/MM/DD'" />
          <div style="color: white; border-top: 1px solid white; width: 2rem; height: 2px; margin-right: 1rem;"></div>
          <a-date-picker v-model:value="endDate" :format="'YYYY/MM/DD'" />
        </a-row>  
      </a-row>
      <highcharts
        id="portfolio-graph"
        :constructorType="'stockChart'"
        class="hc"
        :options="getSelectedGraph"
        ref="chart"
        :style="{ background: 'white' }"
      ></highcharts>
      <div class="for-printer">
        <highcharts
          id="portfolio-graph-printer"
          :constructorType="'stockChart'"
          class="hc"
          :options="getSelectedGraph"
          :style="{ background: 'white' }"
        ></highcharts>
      </div>
    </div>
  </template>
</template>
<script setup>
import { computed, ref, onMounted, watch } from "vue";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { useFundsStore } from "@/stores/FundsStore";
import { numberFormat, charts, setOptions } from "highcharts";
import { convertGraphCurrency } from "@/utility/utility";
import { getMarker, getGraphHeight, getChatPeriods } from "@/utility/helpers";
import { useThemeStore } from "@/stores/ThemeStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useI18n } from "vue-i18n";
import moment from "moment";

const portfolioStore = usePortfolioStore();
const fundsStore = useFundsStore();
const themeStore = useThemeStore();
const authStore = useAuthStore();
const chart = ref(null);
const hoveredSeries = ref(null);
const currency = ref(null);
const startDate = ref(null);
const endDate = ref(null);
const { d, t } = useI18n();

const setCurrency = (curr) => {
  currency.value = curr;
};

const seriesData = computed(() => {
  switch (currency.value) {
    case "EUR":
      return portfolioStore.getNav.map((e) => ({
        id: e.id,
        name: e.title,
        currency: e.currency,
        data: convertGraphCurrency(
          e.graph.map((g) => {
            return [g.date, g.value];
          }),
          e.currency,
          "EUR",
          fundsStore.conversion_ratios
        ),
        marker: {
          symbol: 'circle'
        }
      }));
    case "USD":
      return portfolioStore.getNav.map((e) => ({
        id: e.id,
        name: e.title,
        currency: e.currency,
        data: convertGraphCurrency(
          e.graph.map((g) => {
            return [g.date, g.value];
          }),
          e.currency,
          "USD",
          fundsStore.conversion_ratios
        ),
        marker: {
          symbol: 'circle'
        }
      }));
    default:
      return portfolioStore.getNav.map((e) => ({
        id: e.id,
        name: e.title,
        currency: e.currency,
        data: e.graph.map((g) => {
          return [g.date, g.value];
        }),
        marker: {
          symbol: 'circle'
        }
      }));
  }
});
const navCurrencyData = computed(() => {
  const data = {};
  const navCurrency = portfolioStore.getNavCurrency;
  portfolioStore.getNav.forEach((e) => {
    const serie = navCurrency[e.id];
    if (serie) {
      if (currency.value) {
        data[e.id] = convertGraphCurrency(
          serie,
          e.currency,
          currency.value,
          fundsStore.conversion_ratios
        );
      } else {
        data[e.id] = serie;
      }
    }
  });
  return data;
});

const setDateRange = (rangeSelector) => {
  const selectedRange = rangeSelector?.buttons[rangeSelector?.selected];
  endDate.value = moment();

  if (selectedRange?.type === 'ytd') {
    startDate.value = moment(moment()).startOf('year');
  } else if (selectedRange?.type === 'month') {
    startDate.value = moment().subtract(parseInt(selectedRange?.count), 'months');
  } else if (selectedRange?.type === 'days') {
    startDate.value = moment().subtract(parseInt(selectedRange?.count), 'days');
  } else if (selectedRange?.type === 'year') {
    startDate.value = moment().subtract(parseInt(selectedRange?.count), 'years');
  } else {
    startDate.value = moment(seriesData?.value[0]?.data[0][0]);
  }
};

const changeRange = (range) => {
  portfolioStore.graphOptions = {
    ...portfolioStore.graphOptions,
    rangeSelector: {
      ...chart.value.chart.options.rangeSelector,
      selected: range
    }
  };
};

const rangeButtons = computed(() => {
  const chartConfig = authStore?.config['chart_config'];
  const periodButtons = getChatPeriods(chartConfig);
  return periodButtons;
});

const getSelectedGraph = computed(() => {
  const series = seriesData.value;
  const graphData = getGraphHeight(window.innerWidth, authStore.pdfConfig);
  const height = graphData?.graph_height;
  const rangeSelector = portfolioStore.graphOptions?.rangeSelector;
  setOptions({
    lang:{
      rangeSelectorZoom: t('chart.zoom')
    }
  })
  return {
    rangeSelector,
    legend: {
      useHTML: true,
      enabled: true,
      labelFormatter: function() {
        return `
          <div style="display: flex; align-items: center; gap: 4px;">
            <div style="border-radius: 100%; width: 8px; height: 8px; background: ${this.color}"></div>
            ${this.name}
          </div>
        `;
      }
    },
    tooltip: {
      headerFormat: '',
      pointFormatter: function () {
        const date = d(new Date(this.x), 'short');
        const navCurrency = navCurrencyData.value;
        let tooltip = '';
        if (this.series.name === hoveredSeries.value?.userOptions.name) {
          tooltip += `<strong>${date}</strong><br>`;
          const selectedCurrency = currency.value
            ? currency.value
            : this.series.userOptions.currency;
          const value = this.y;
          const percentageValue = numberFormat(this.change, 2);
          let currencyValue = null;
          if (navCurrency && navCurrency[this.series.name]) {
            const found = navCurrency[this.series.name].find(
              (e) => e[0] === this.x
            );

            if (found) {
              currencyValue = found[1];
            }
          }
          const formattedValue = numberFormat(
            currencyValue !== null ? currencyValue : value,
            2
          );

          tooltip +=
            `<span style="color: ${this.color}">${getMarker(this.series.symbol)}&nbsp;<strong>${this.series.name}: ${percentageValue}%</strong> ${formattedValue} ${selectedCurrency}</span><br/>`;

        }
        return tooltip;
      },
      shared: false,
      split: false,
      changeDecimals: 2,
      valueDecimals: 2,
    },
    yAxis: {
      labels: {
        formatter: function () {
          return (this.value > 0 ? " + " : "") + this.value + "%";
        },
      },
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%d-%m-%Y}",
      },
      crosshair: false,
      tooltip: false,
      events: {
        afterSetExtremes: (e) => {
          startDate.value = moment(e.min);
          endDate.value = moment(e.max);
        }
      }
    },
    plotOptions: {
      series: {
        compare: "percent",
        compareStart: true,
        events: {
          mouseOver: function (event) {
            hoveredSeries.value = event?.target;
          },
          mouseOut: function () {
            hoveredSeries.value = null;
          }
        }
      },
    },
    navigator: {
      outlineColor: 'rgba(0, 0, 0, 0)',
      maskFill: 'rgb(2, 180, 135)',
    },
    series,
    chart: {
      height,
    },
  };
});

onMounted(() => {
  let from = null;
  const navs = portfolioStore.selectedPortfolio?.nav || null;
  if (navs) {
    const firstNav = navs[Object.keys(navs)[0]];
    from = Object.keys(firstNav)[0];
  }
  fundsStore.getConversionRatios({ from });
  const chartConfig = authStore?.config['chart_config'];
  const periodButtons = getChatPeriods(chartConfig);
  const initialOptions = {};
  initialOptions['rangeSelector'] = {
    buttons: periodButtons.map(btn => ({ ...btn, text: t(`chart.range.${btn.transKey}`, btn.transKey) })),
    selected: chartConfig['extension_periods']?.indexOf(chartConfig?.default_period),
    enabled: true,
  };
  portfolioStore.graphOptions = initialOptions;
  setDateRange(initialOptions?.rangeSelector);
});

watch(
  () => portfolioStore?.graphOptions,
  (options) => {
    setDateRange(options?.rangeSelector);
  }
);

watch(currency, () => {
  const chart = charts[0];
  if (chart) {
    chart.tooltip.refresh(chart.series[0].points[0]);
  }
});

watch(
  () => themeStore.collapsedMenu,
  () => {
    setTimeout(() => {
      chart.value?.chart.reflow();
    }, 300);
  }
);

watch(
  () => startDate.value,
  (newVal) => {
    if (chart.value) {
      chart.value.chart.xAxis[0].setExtremes(newVal?.valueOf(), endDate.value?.valueOf());
    }
  }
);

watch(
  () => endDate.value,
  (newVal) => {
    if (chart.value) {
      chart.value.chart.xAxis[0].setExtremes(startDate.value?.valueOf(), newVal.valueOf());
    }
  }
);
</script>
<style scoped lang="scss">
.for-printer {
  width: 100%;
  position: fixed;
  bottom: 0;
  transform: translateY(200%);
}
.graph-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #004851;
  padding: 4px 16px;
  .convert-btn {
    font-size: 12px;
    padding: 4px 12px !important;
    border-radius: 25px !important;
    color: white !important;
    height: fit-content !important;
    background-color: transparent;
    border: 1px solid white;
    &:hover {
      background-color: white;
      color: #08A07D !important;
    }
    &.active {
      background-color: white !important;
      color: #08A07D !important;
    }
  }
  .range-btn {
    font-size: 12px;
    padding: 4px 12px !important;
    border-radius: 25px !important;
    color: white !important;
    height: fit-content !important;
    background-color: transparent;
    border: none;
    &.outline {
      border: 1px solid white;
    }
    &:hover {
      background-color: white;
      color: #08A07D !important;
    }
  }
}
:deep(.highcharts-root) {
  box-shadow: 0px 3px 6px #00000029;
  background-color: #F2F2F2;
}
:deep(.highcharts-navigator-mask-outside) {
  fill: #484848;
}
:deep(.highcharts-credits) {
  display: none;
}
:deep(.highcharts-range-selector-buttons) {
  display: none;
}
:deep(.highcharts-input-group) {
  display: none;
}
:deep(.ant-calendar-picker-input) {
  border: none;
  background-color: transparent;
  color: white !important;
  width: 6rem;
  cursor: pointer;
  &::placeholder {
    display: none !important;
  }
}
:deep(.ant-calendar-picker-icon) {
  display: none;
}
:deep(.ant-calendar-picker-clear) {
  display: none;
}
:deep(.ant-calendar-picker-container) {
  margin-top: 2.5rem !important;
}
:deep(.highcharts-legend-item) {
  & path {
    display: none;
  }
}
:deep(.highcharts-background) {
  fill: #F2F2F2;
}
.the-graph {
  box-shadow: 0px 3px 6px #00000029;
}
</style>
