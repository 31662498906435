<template>
    <div>
        <label>{{ $t("portfolio_control.benchmark_comparisons") }}</label>
        <a-row class="limit-items">
            <div
                v-for="(limit, index) in props.config"
                :key="index"
                class="limit-item"
            >
                <div style="width: 24px; height: 12px;" :style="{ background: limit.color }"></div>:
                {{ t(`portfolio_control.${limit.label}`) }}
            </div>
        </a-row>
    </div>
</template>
<script setup>
import { defineProps, onMounted } from 'vue';
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps({
    config: {
        type: Object,
        default: null
    }
});

onMounted(() => {
    console.log("props.config: ", props.config);
});
</script>
<style lang="scss">

.limit-items {
    gap: 12px;
    margin-right: 12px;
    flex-direction: column;
}

.limit-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 2px;
}
</style>