<template>
  <div class="portfolio-overview">
    <sdPageHeader
      :title="$t('portfolio_overview.title') + simulationName"
    >
      <template v-slot:buttons>
        <div
          class="page-header-actions"
          v-if="portfolioStore.selectedPortfolio"
        >
          <label
            class="title-btn"
            v-if="
              portfolioStore.selectedPortfolio?.nav &&
              !authStore.restrictions.includes(Restrictions['SavePortfolio'])
            "
            @click="handleSavePortfolio"
          >
            <span>{{ $t("portfolio_overview.save_portfolio") }}</span>
            <span class="asterisk" :style="{ color: headerUnderline ? '#4D00FA' : '#02B388' }">*</span>
          </label>
          <label
            class="title-btn"
            v-if="!authStore.restrictions.includes(Restrictions['CleanPortfolio'])"
            @click="handleCleanVisible"
          >
            <span>{{ $t("portfolio_overview.clean_portfolio") }}</span>
            <span class="asterisk" :style="{ color: headerUnderline ? '#4D00FA' : '#02B388' }">*</span>
          </label>
        </div>
      </template>
    </sdPageHeader>
    <Main>
      <div class="portfolio-summary" v-if="portfolioStore.selectedPortfolio">
        <portfolio-resume></portfolio-resume>
        <a-row style="align-items: center; margin-bottom: 2.5rem;">
          <asset-info :datasets="assetTypesSets" />
          <region-info :datasets="regionsSets" />
        </a-row>
        <table-printer
          :datasets="getDatasets"
          :key-title="$t('portfolio_overview.columns.portfolio')"
        ></table-printer>
      </div>
      <div style="margin-top: 1.5rem;" id="portfolio-table-part">
        <portfolio-table-card></portfolio-table-card>
      </div>
    </Main>
  </div>

  <a-modal
    v-model:visible="visibleSaveModal"
    :title="modalSaveTitle"
    :type="modalType"
    @ok="handleSaveOk"
    @cancel="handleCancel"
  >
    <template #footer>
      <a-button key="back" @click="handleCancel">{{
        $t("generic.buttons.cancel")
      }}</a-button>
      <a-popconfirm
        :title="$t('portfolio_overview.save_portfolio_message')"
        :ok-text="$t('search_funds.select_family_columns.yes')"
        placement="left"
        :cancel-text="$t('search_funds.select_family_columns.no')"
        @confirm="handleSaveOk"
        v-if="shouldConfirmSave"
      >
        <a-button
          key="submit"
          type="primary"
          :loading="portfolioStore.loading"
          :disabled="
            savePortfolioData.amount_invest && savePortfolioData.amount_invest > 0
              ? false
              : true
          "
        >
          {{ $t("generic.buttons.ok") }}
        </a-button>
      </a-popconfirm>
      <a-button
        key="submit"
        type="primary"
        :loading="portfolioStore.loading"
        :disabled="
          savePortfolioData.amount_invest && savePortfolioData.amount_invest > 0
            ? false
            : true
        "
        @click="handleSaveOk"
        v-else
      >
        {{ $t("generic.buttons.ok") }}
      </a-button>
    </template>
    <a-col :xs="20">
      <label class="mb-3">{{ $t("portfolio_overview.invest_amount") }}</label>
      <a-input
        v-if="
          !portfolioStore.selectedPortfolio?.summary.divisabase ||
          !portfolioStore.selectedPortfolio?.summary.divisa_base
        "
        v-model:value="savePortfolioData.amount_invest"
        type="number"
        min="0"
        class="w-100"
        :placeholder="$t('portfolio_overview.invest_amount_placeholder')"
        style="height: 45px; width: 100%"
        :disabled="portfolioStore.portfolio_type > 1 ? true : false"
      />
      <div>
        <label class="my-3">{{
          $t("portfolio_overview.portfolio_name")
        }}</label>
        <a-select
          style="width: 100%;"
          v-model:value="savePortfolioData.portfolio_name"
          :placeholder="$t('portfolio_overview.enter_portfolio_name')"
        >
          <a-select-option v-for="item in simulations" :key="item" :value="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </div>
      <template v-if="portfolioStore.portfolio_type !== 3">
        <div class="mt-3">
          <label class="my-3">{{ $t("portfolio_overview.saved_portfolio_name") }}</label>
          <a-input
            v-model:value="savePortfolioData.saved_portfolio_name"
            class="w-100"
            style="height: 45px; width: 100%"
          />
        </div>
        <div class="mt-3">
          <a-checkbox v-model:checked="savePortfolioData.save_portfolio">
            {{ $t("portfolio_overview.save_portfolio_label") }}
          </a-checkbox>
        </div>
      </template>
    </a-col>
  </a-modal>

  <a-modal
    v-model:visible="visibleCleanModal"
    :title="modalCleanTitle"
    :type="modalType"
    @ok="handleCleanOk"
    @cancel="handleCancel"
  >
    {{ $t("portfolio_overview.clean_portfolio_message") }}
  </a-modal>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { Main } from "../styled";

import tablePrinter from "@/components/wiseral/tablePrinter.vue";
import AssetInfo from "@/components/wiseral/portfolio/AssetInfo.vue";
import RegionInfo from "@/components/wiseral/portfolio/RegionInfo.vue";
import { usePortfolioStore } from "@/stores/PortfolioOverviewStore";
import { usePortfolioLimitStore } from "@/stores/PorfolioLimitsStore";

import PortfolioTableCard from "@/components/wiseral/portfolio/PortfolioTableCard.vue";
import PortfolioResume from "@/components/wiseral/portfolio/PortfolioResume.vue";
import { message } from "ant-design-vue";

import { downloadFile } from "@/utility/utility";

import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import Restrictions from '../../config/restriction/restrict_functions';

const { t, locale } = useI18n();
const route = useRoute();
const portfolioStore = usePortfolioStore();
const portfolioLimitsStore = usePortfolioLimitStore();

const authStore = useAuthStore();
const visibleSaveModal = ref(false);
const modalType = ref("primary");
const modalSaveTitle = t("portfolio_overview.save_portfolio");
const visibleCleanModal = ref(false);
const modalCleanTitle = t("portfolio_overview.clean_portfolio");
const simulations = ref([]);
const headerUnderline = ref(false);

// const modeOption = ref(0);

const setHeaderUnderline = (value) => {
  headerUnderline.value = value;
};

const shouldConfirmSave = computed(() => {
  return (portfolioStore.portfolio_type === 1 || portfolioStore.portfolio_type === 2) && (
    portfolioStore.listOfPortfolios.find(e => e.portfolio_name === savePortfolioData.value.saved_portfolio_name)) && (
    savePortfolioData.value.save_portfolio
  );
});

const simulationName = computed(() => {
  let simulationNameValue = '';

  if (portfolioStore.selectedPortfolio?.nav) {
    const ports = Object.keys(portfolioStore.selectedPortfolio?.nav || {});
    if (ports?.length === 1) {
      simulationNameValue = ` - ${ports[0]}`;
    } else if (ports.length > 1) {
      setHeaderUnderline(true);
    }
  }

  return simulationNameValue;
});

const savePortfolioData = ref({
  portfolio_name: "",
  save_portfolio: false,
  amount_invest: authStore.config["default_investment_amount"],
  locale: 'en',
  saved_portfolio_name: ""
});

const handleSavePortfolio = () => {
  if (!visibleSaveModal.value) {
    simulations.value = Object.keys(portfolioStore.selectedPortfolio?.nav || {});
    savePortfolioData.value.portfolio_name = simulations.value[0];
    savePortfolioData.value.save_portfolio = false;
    savePortfolioData.value.locale = locale;
    const summary = portfolioStore.selectedPortfolio?.summary ?? {};
    let baseValue = null, cashValue = null;
    if (Object.keys(summary).includes(authStore.config['divisa_base'])) {
      const currencyValues = summary?.[authStore.config['divisa_base']] || {};
      if ("total" in currencyValues) {
        baseValue = currencyValues['total'];
      } 
      else if ("Current" in currencyValues) {
        baseValue = currencyValues['Current'];
      } 
      else {
        const firstKey = Object.keys(currencyValues)[0];
        baseValue = currencyValues[firstKey];
      }
    }
    if (Object.keys(summary).includes('CASH')) {
          const cashValues = summary?.['CASH'] || {};
          if ("total" in cashValues) {
            cashValue = cashValues['total'];
          } 
          else if ("Current" in cashValues) {
            cashValue = cashValues['Current'];
          } 
          else {
            const firstKey = Object.keys(cashValues)[0];
            cashValue = cashValues[firstKey];
          }
    }
    let amount = !baseValue ? authStore.config["default_investment_amount"] : (baseValue - cashValue);
    savePortfolioData.value.amount_invest = amount;
    savePortfolioData.value.saved_portfolio_name = portfolioStore.original_portfolio_name ?? simulations.value[0];
  }
  visibleSaveModal.value = !visibleSaveModal.value;
};

const handleCleanVisible = () => {
  visibleCleanModal.value = !visibleCleanModal.value;
};

const handleSaveOk = async () => {
  const result = await portfolioStore.savePortfolio({
    ...savePortfolioData.value,
    update_allow: shouldConfirmSave.value && true
  });
  if (result) {
    visibleSaveModal.value = false;
    result.orders
       ? downloadFile(
           result.orders,
           `orders-${savePortfolioData.value.saved_portfolio_name}.csv`
         )
       : "";
    result.portfolio
      ? downloadFile(
          result.portfolio,
          `portfolio-${savePortfolioData.value.saved_portfolio_name}.csv`
        )
      : "";
    message.success(t("portfolio_overview.ok_order"));
    //savePortfolioData.value.amount_invest = 0;
  }
};

const handleCleanOk = () => {
  portfolioStore.portfolio_type = null;
  portfolioStore.original_portfolio_name = null;
  portfolioStore.last_update_date = null;
  portfolioStore.portfolio_id = null;
  visibleCleanModal.value = false;
  portfolioStore.clearPortfolio();
};

const handleCancel = () => {
  visibleSaveModal.value = false;
  visibleCleanModal.value = false;
};
const assetTypesSets = computed(() => {
  return portfolioStore.getCompositionsVisible(false)[0];
});
const regionsSets = computed(() => {
  return portfolioStore.getCompositionsVisible(false)[1];
})
const getDatasets = computed(() => {
  return portfolioStore.getRatios;
});

onMounted(async () => {
  if (!portfolioStore.selectedPortfolioIds.length && route.query.params) {
    await portfolioStore.loadPortfolios(route.query.params);
  }
  savePortfolioData.value.amount_invest = authStore.config["default_investment_amount"];
  savePortfolioData.value.save_portfolio = false;
  if (portfolioLimitsStore.categories?.length === 0) {
    await portfolioLimitsStore.getDefaultCategories();
  }
  if (portfolioStore.savedLimits?.length === 0) {
    await portfolioStore.fetchLimits();
  }
});
</script>
<style scoped lang="scss">
@import "~@/static/css/colors.scss";

.title-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  & span:first-child {
    &:hover {
      text-decoration: underline;
    }
  }
  & .asterisk {
    color: $text-light-color;
    padding-top: 4px;
  }  
}
.portfolio-overview {
  margin: -50px -96px 0 -96px;
  background: #F8F8F8;
}
.portfolio-summary {
  padding: 0 96px 24px !important;
}
@media only screen and (max-width: 768px) {
  .portfolio-summary {
    padding: 0 0 !important;
  }
  .portfolio-overview {
    margin: -50px 0 0 0;
  }
}
:deep(.ant-select-selector) {
  align-items: center;
  border-color: black !important;
}
:deep(.ant-table) {
  background: none;
}
:deep(table) {
  thead.ant-table-thead tr th {
    background-color: $table-headers-background !important;
    color: white !important;
    font-weight: 500 !important;
    padding: 8px 16px !important;
    border-radius: 0 !important;
  }
  thead.ant-table-thead tr th:first-child {
    text-align: left !important;
  }
  tbody.ant-table-tbody tr td {
    padding: 8px 16px !important;
    border-bottom: 1px solid;
    border-right: 1px solid;
  }

  tbody.ant-table-tbody tr td:last-child {
    border-right: none;
  }

  tbody.ant-table-tbody tr td:first-child {
    border-left: none;
    text-align: left !important;
  }

  table.ant-table {
    border-radius: 0 !important;
  }
}
:deep(.ant-page-header) {
  padding-bottom: 0 !important;
  margin: 0 96px 24px 96px;
  @media only screen and (max-width: 768px) {
    & {
      margin: 0 24px 24px;
    }
  }
  .ant-page-header-heading-left {
    margin: 0 !important;
  }
}
</style>
