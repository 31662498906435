import { defineStore } from "pinia";
import client from "@/plugins/axios";
import { useAuthStore } from "./AuthStore";

export const usePortfolioControlStore = defineStore("usePortfolioControlStore", {
  state: () => {
    return {
      controlData: null,
      loading: false,
      error: false,
      graphOptions: {},
      columns: [],
      selectedPageSize: 20,
      selectedPortfolios: [],
    };
  },
  getters: {
    getColumns: (state) => {
      if (!state.controlData?.dashboard) {
        return {};
      }
      const columns = [];
      state.controlData?.dashboard?.forEach(e => {
        Object.keys(e).forEach(col => {
          if (!columns.includes(col) && col !== 'id' && col !== 'compared') {
            columns.push(col);
          }
        });
      });
      return columns;
    },
    getNav: (state) => {
      const authStore = useAuthStore();
      const navs = state.controlData?.nav || {};
      const configStart = authStore.chartRange;
      return Object.keys(navs).filter(nav => state.selectedPortfolios.find(e => e?.name === nav)).map((key) => {
        const nav = navs[key];
        return {
          id: key,
          title: key,
          currency: "EUR",
          graph: Object.keys(nav)
            .filter(item => configStart ? new Date(item) > new Date(configStart) : true)
            .map((dateString) => {
              const date = new Date(dateString);
              const dateTime = date.getTime();
              const offset = date.getTimezoneOffset() * 60000;
              return {
                date: dateTime - offset,
                value: nav[dateString] || 0,
              };
            }),
        };
      });
    },
    getPortfoliosTable: (state) => {
      const navs = Object.keys(state.controlData?.nav || {});
      console.log(state.controlData?.benchmarks)
      const result = state.controlData?.dashboard?.map((e, i) => {
        const row = e;
        const compared = {};
        console.log(e?.risk);
        // Buscar el benchmark correspondiente
        const benchmark = state.controlData?.benchmarks?.find(b => b.risk === e?.risk);
        console.log(benchmark);
        // Realizar la comparación solo si benchmark se encontró
        if (benchmark) {
          Object.keys(e).forEach(key => {
            // Realizar la comparación solo si la propiedad existe en el benchmark
            if (benchmark[key] !== undefined && benchmark[key] !== null) {
              compared[key] = e[key] - benchmark[key];
            } else {
              compared[key] = null;  
            }
          });
        } 
        row['compared'] = compared;
        row['name'] = navs[i];
        return row;
      });
      console.log("result: ", result)
      return result;
    }
  },
  actions: {
    async getPanelData () {
      try {
        this.loading = true;
        return client.get('/portfolio/control').then((response) => {
          this.controlData = response.data.data;
          this.loading = false;
          return response.data;
        });
      } catch (err) {
        this.loading = false
        this.error = err;
      }
    },
    async rebalance (ids) {
      try {
        return client.post('/portfolio/rebalance', { ids }).then((response) => {
          return response.data;
        });
      } catch (err) {
        this.error = err;
      }
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "portfolioControlData",
        storage: localStorage,
      },
    ],
  },
});