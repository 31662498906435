<template>
  <template v-if="!props.datasets">
  </template>
  <template v-else>
    <template v-for="(d, i) in tableData" v-bind:key="i">
      <div class="mobile-table">
        <h5 v-if="displayHeaders">
          {{ $t(`portfolio_overview.columns.${d.title}`, d.title) }}
        </h5>
        <a-table
          v-bind:key="i"
          :columns="mapColumns(d.columns)"
          :data-source="d.data"
          :pagination="false"
        >
        </a-table>
      </div>
    </template>
  </template>
</template>

<script setup>
import { defineProps, computed } from "vue";
import { useI18n } from "vue-i18n";
import { customNumberFormatter } from "../../utility/helpers";

const { t, n, locale } = useI18n();
const props = defineProps({
  datasets: {
    type: Array,
    default: function () {
      return [];
    },
  },
  keyTitle: {
    type: String,
    default: "",
  },
  displayHeaders: {
    type: Boolean,
    default: false,
  },
  sortable: {
    type: Boolean,
    default: false
  }
});

const mapColumns = (columns) => {
  const headers = columns.map((header) => {
    return {
      title: <a-tooltip title={t(`portfolio_overview.columns.tooltips.${header}`, header)}>{t(`positions_fields.${header}`, header)}</a-tooltip>,
      key: header,
      dataIndex: header,
      sorter: props.sortable,
      align: "center",
    };
  });
  headers.unshift({
    title: props.keyTitle,
    key: "key",
    dataIndex: "key",
    sorter: props.sortable,
    align: "center",
  });
  return headers;
};

const tableData = computed(() => {
  return props.datasets.map((row) => {
    const formats = row.formats;
    const data = row.data.map((element) => {
      const keys = Object.keys(element);
      let rowData = {};
      keys.forEach((columnName) => {
        const value = element[columnName];
        if (value == null || value === "") {
          return "";
        }
        const format = formats[columnName]?.format;
        const decimalQty = formats[columnName]?.decimalQty;
        if (["decimal", "percent", "currency"].includes(format)) {
          rowData[columnName] = customNumberFormatter(n, value, format, decimalQty, locale.value);
        } else if (["boolean", "bool"].includes(format)) {
          value === 0
            ? (rowData[columnName] = t(
                "portfolio_overview.columns.false",
                false
              ))
            : (rowData[columnName] = t(
                "portfolio_overview.columns.true",
                true
              ));
        } else {
          rowData[columnName] = value;
        }
      });
      return rowData;
    });
    return {
      ...row,
      data,
    };
  });
});
</script>
<style scoped lang="scss">
.mobile-table {
  padding: 10px 5px;
  overflow-x: auto;
  white-space: nowrap;
  th {
    font-size: 14px !important;
    font-weight: 500 !important;
    min-width: 120px !important;
  }
}
</style>
<style>
.column-money {
  text-align: right !important;
}
</style>
