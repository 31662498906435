<template>
  <div class="clearfix">
    <span class="float-left" style="font-size: 14px; font-weight: bold;">
      <span>{{
        customNumberFormatter(n, props.position.value, props.value.format, props.value.decimalQty, locale)
      }}</span>
    </span>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { customNumberFormatter } from "../../utility/helpers";

const { n, locale } = useI18n();

const props = defineProps({
  value: { type: Object, default: null },
  position: { type: Object, default: null },
});
</script>
