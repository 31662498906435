<template>
  <div class="portfolio-table">
    <TableGraphSwitcher @change-viewmode="changeView" :isPortfolio="1" />
    <a-row>
      <a-col :sm="24" :xs="24" v-if="!isGraph">
        <div class="portfolio-basket-funds">
          <portfolio-table></portfolio-table>
        </div>
      </a-col>
      <a-col :xs="24" v-else>
        <PortfolioGraph></PortfolioGraph>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import { ref } from "vue";
import PortfolioTable from "./PortfolioTable.vue";
import PortfolioGraph from "../PortfolioGraph.vue";
import TableGraphSwitcher from "../TableGraphSwitcher.vue";

const isGraph = ref(false);

const changeView = (e) => {
  isGraph.value = e;
};

</script>
<style scoped lang="scss">
.portfolio-basket-funds {
  .switcher {
    margin-bottom: 20px;
  }
}
.portfolio-table {
  background: #f2f2f2;
  padding: 72px 96px 1rem;
}
@media only screen and (max-width: 768px) {
  .portfolio-table {
    padding: 8px !important;
  }
}

</style>
